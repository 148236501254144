
import { defineComponent, ref } from "vue";
import { GenerateInput, generate } from "@/lib/backend";

export default defineComponent({
	name: "HomeView",
	setup: () => {
		const stage = ref<"input" | "generation">("input");

		/* user input */

		const user = ref<GenerateInput["user"]>({
			bio: "",
			experience: []
		});

		const githubUsername = ref("");
		const isImportingFromGithub = ref(false);

		const importFromGithub = async () => {
			const getJson = async (url: string) =>
				await (await fetch(url)).json();

			isImportingFromGithub.value = true;

			try {
				const [githubProfile, githubRepositories] = await Promise.all([
					getJson(
						`https://api.github.com/users/${githubUsername.value}`
					),
					getJson(
						`https://api.github.com/users/${githubUsername.value}/repos`
					)
				]);

				console.log({
					githubProfile,
					githubRepositories
				});

				const bio = githubProfile.bio as string | null | undefined;

				const languages = new Set(
					(githubRepositories as any[])
						.map((repo) => repo.language)
						.filter((language) => typeof language === "string")
				);

				user.value.bio = `${
					typeof bio === "string" ? `${bio}\n\n` : ""
				}Skills: ${[...languages].join(", ")}`;
			} finally {
				isImportingFromGithub.value = false;
			}
		};

		const addExperience = () => {
			user.value.experience.push({
				position: "",
				organisation: "",
				start: 2020,
				end: 2023
			});
		};

		/* cover letter generation */

		const jobDescription = ref<string>("");

		const isGenerating = ref<boolean>(false);
		const result = ref<undefined | string | null>();

		const go = async () => {
			isGenerating.value = true;

			try {
				const { response } = await generate({
					user: user.value,
					jobDescription: jobDescription.value
				});

				result.value = response;
			} finally {
				isGenerating.value = false;
			}
		};

		return {
			stage,
			user,
			githubUsername,
			importFromGithub,
			isImportingFromGithub,
			addExperience,
			jobDescription,
			go,
			result,
			isGenerating
		};
	}
});
