import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "home"
}
const _hoisted_2 = { class: "job" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "job" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = {
  key: 1,
  class: "home"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.stage === 'input')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "Github Username (Optional)",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.githubUsername) = $event))
            }, null, 512), [
              [_vModelText, _ctx.githubUsername]
            ]),
            _createElementVNode("button", {
              disabled: _ctx.isImportingFromGithub,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.importFromGithub && _ctx.importFromGithub(...args)))
            }, [
              (!_ctx.isImportingFromGithub)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Import from Github"))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "Loading..."))
            ], 8, _hoisted_3)
          ]),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.bio) = $event)),
            placeholder: "Describe Yourself",
            rows: "5"
          }, null, 512), [
            [_vModelText, _ctx.user.bio]
          ]),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addExperience && _ctx.addExperience(...args)))
          }, "Add Experience"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.experience, (job) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((job.position) = $event),
                placeholder: "Position"
              }, null, 8, _hoisted_7), [
                [_vModelText, job.position]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((job.organisation) = $event),
                placeholder: "Organisation"
              }, null, 8, _hoisted_8), [
                [_vModelText, job.organisation]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": ($event: any) => ((job.end) = $event),
                placeholder: "End Year"
              }, null, 8, _hoisted_9), [
                [_vModelText, job.end]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": ($event: any) => ((job.start) = $event),
                placeholder: "Start Year"
              }, null, 8, _hoisted_10), [
                [_vModelText, job.start]
              ])
            ]))
          }), 256)),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.stage = 'generation'))
          }, "Continue")
        ]))
      : _createCommentVNode("", true),
    (_ctx.stage === 'generation')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.jobDescription) = $event)),
            placeholder: "Job Description",
            rows: "7"
          }, null, 512), [
            [_vModelText, _ctx.jobDescription]
          ]),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.go && _ctx.go(...args))),
            disabled: _ctx.isGenerating
          }, [
            (!_ctx.isGenerating)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Generate"))
              : (_openBlock(), _createElementBlock("span", _hoisted_14, "Loading..."))
          ], 8, _hoisted_12),
          (_ctx.result)
            ? (_openBlock(), _createElementBlock("pre", _hoisted_15, _toDisplayString(_ctx.result), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}