const BACK_END_URL = process.env.VUE_APP_BACKEND_URL || "http://localhost:8080";

export type GenerateInput = {
	user: {
		bio: string;
		experience: {
			position: string;
			organisation: string;
			start: number;
			end: number;
		}[];
	};
	jobDescription: string;
};

export const generate = async (input: GenerateInput) => {
	const response = await fetch(`${BACK_END_URL}/generate`, {
		method: "POST",
		body: JSON.stringify(input),
		headers: {
			"Content-Type": "application/json"
		}
	});

	return (await response.json()) as { response: string | null | undefined };
};
