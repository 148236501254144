import {
	createRouter,
	createWebHashHistory,
	RouteRecordRaw,
	createWebHistory
} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: HomeView
	}
];

const createHistory =
	typeof process.env.VUE_APP_USE_HTML5_HISTORY === "string"
		? createWebHistory
		: createWebHashHistory;

const router = createRouter({
	history: createHistory(),
	routes
});

export default router;
